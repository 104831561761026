.block-title {
  color: #f6f6f6;
  margin-bottom: 48px;
  text-align: center;
}

.main-logo {
  width: 195.98px;
  height: 32.52px;
  margin-bottom: 30px;
}

.main-title {
  font-weight: 700;
  font-size: 30px;
  line-height: 34px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  max-width: 678px;
  margin: 0 auto 16px;
  @media screen and (min-width: 768px) {
    font-size: 48px;
    line-height: 52px;
  }
}

.text {
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  margin: 0;
  opacity: 0.8;
  @media screen and (min-width: 768px) {
    font-size: 24px;
  }
}

.heroes-main {
  position: relative;
  justify-content: space-around;
  z-index: 1;
  @media screen and (min-width: 768px) {
    justify-content: space-between;
  }
  a {
    display: inline-block;
    background: #27272f;
    border: 1px solid #4e4e4e;
    box-shadow: 0px 4px 37px rgba(0, 0, 0, 0.63);
    padding: 12px;
    border-radius: 12px;
    margin: 0 0 15px;
    position: relative;
    text-decoration: none;
    width: 100%;
    max-width: 130px;
    @media screen and (min-width: 375px) {
      max-width: 155px;
    }
    @media screen and (min-width: 768px) {
      padding: 20px;
      max-width: 214px;
      height: 104px;
      margin: 0 0 30px;
    }
    &:hover {
      border: 0;
      box-shadow: 0px 4px 37px rgba(0, 140, 241, 0.63);
      padding: 13px;
      @media screen and (min-width: 768px) {
        padding: 21px;
      }
      $border: 2px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        opacity: 0.7;
        margin: -$border; /* !importanté */
        border-radius: inherit; /* !importanté */
        background: rgb(0, 132, 206);
        background: linear-gradient(
          90deg,
          rgba(0, 132, 206, 1) 2%,
          rgba(9, 9, 121, 1) 35%,
          rgba(119, 206, 255, 1) 100%
        );
      }
      .bg-image {
        background-image: url(../../public/images/pngwing.png);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        border: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        mix-blend-mode: soft-light;
        opacity: 0.7;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          180deg,
          rgba(0, 148, 255, 0.46) 0%,
          rgba(38, 116, 172, 0) 39.9%,
          rgba(0, 148, 255, 0.46) 100%
        );
        border-radius: 12px;
        opacity: 0.7;
        background-repeat: no-repeat;
      }
      .hero-wrapper-main {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: linear-gradient(
            90deg,
            rgba(0, 132, 206, 1) 2%,
            rgba(9, 9, 121, 1) 35%,
            rgba(119, 206, 255, 1) 100%
          );
          border: 0 solid transparent;
          opacity: 0.7;
          border-radius: 7px;
        }
        img {
          z-index: 0;
          border: 2px solid transparent;
        }
      }
    }
  }
}

.hero-wrapper-main {
  overflow: hidden;
  position: relative;
  width: 44px;
  height: 44px;
  @media screen and (min-width: 768px) {
    width: 64px;
    height: 64px;
  }
  img {
    border: 2px solid #4e4e4e;
    border-radius: 8px;
    z-index: 1;
  }
}

.paragraph {
  color: #f6f6f6;
  padding-left: 12px;
}

.heroes {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  justify-content: space-between;
  @media screen and (min-width: 768px) {
    margin-bottom: 60px;
  }
}

.hero-wrapper {
  position: relative;
  margin: 10px;
  width: 64px;
  height: 64px;
  border: 2px solid #4e4e4e;
  border-radius: 8px;
  &:hover {
    &::after {
      opacity: 0;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #27272f;
    opacity: 0.5;
  }
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    position: absolute;
  }
}

a.active {
  position: relative;
  .hero-wrapper {
    border: 0;
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    &::before {
      background: linear-gradient(
        90deg,
        rgba(0, 132, 206, 1) 2%,
        rgba(9, 9, 121, 1) 35%,
        rgba(119, 206, 255, 1) 100%
      );
      border: 0 solid transparent;
      border-radius: 8px;
      opacity: 0.7;
      box-shadow: 0px 4px 37px rgba(0, 140, 241, 0.63);
    }
    &::after {
      background-image: url(../../public/images/pngwing.png);
      background-repeat: no-repeat;
      background: linear-gradient(
        180deg,
        rgba(0, 148, 255, 0.46) 0%,
        rgba(38, 116, 172, 0) 39.9%,
        rgba(0, 148, 255, 0.46) 100%
      );
      border-radius: 12px;
      opacity: 0.7;
    }
    img {
      z-index: 0;
      border: 3px solid transparent;
    }
  }
}

.title-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 24px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.sub-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 35px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #f6f6f6;
  margin: 0;
  margin-bottom: 24px;
  @media screen and (min-width: 768px) {
    margin-bottom: 0;
    font-size: 40px;
    line-height: 43px;
  }
}

.cat-block {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #f6f6f6;
  @media screen and (min-width: 768px) {
    font-size: 18px;
  }
}
