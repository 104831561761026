.glyph-modal-content {
  background: #1b1b22;
  border: 1px solid #4e4e4e;
  border-radius: 12px;
  padding: 24px;
  z-index: 9999;
  width: auto;
  @media screen and (min-width: 1024px) {
    width: 900px;
  }
  .spec-reset {
    margin: 0 0 15px auto;
    cursor: pointer;
    border: none;
  }
  .content {
    height: 54vh;
    width: 100%;
    overflow-y: scroll;
    margin-bottom: 24px;
  }
}

.list-item {
  background: #141418;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 8px;
  height: auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #5c5c68;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (min-width: 768px) {
    height: 62px;
    flex-direction: row;
  }

  .g-name {
    color: #fff;
    width: 100%;
    max-width: 210px;
    padding-right: 15px;
    margin-bottom: 8px;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }
}

.modal-img {
  width: 42px;
  height: 42px;
  border: 2px solid #4e4e4e;
  border-radius: 8px;
  margin-bottom: 8px;
  @media screen and (min-width: 768px) {
    margin-right: 8px;
    margin-bottom: 0;
  }
}

.name {
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #f7f7f7;
}

.wrapper-Img {
  border: 2px solid #4e4e4e;
  border-radius: 8px;
  margin-right: 8px;
  width: 42px;
  height: 42px;
  overflow: hidden;
}
