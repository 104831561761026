* {
  font-family: "CeraPro";
}

.spec {
  height: 812px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 5px;
  padding: 38px 15px;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  position: relative;
}

.skill {
  display: block;
  margin: 10px;
  width: 42px;
  height: 42px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
    border-radius: 6px;
  }

  &.active {
    background: linear-gradient(250.55deg, #53D148 25.24%, rgba(83, 209, 72, 0) 52.89%, #53D148 90.19%);
    border: 2px solid transparent;
    .badge {
      color: #52D148;
    }
  }


  &.done {
    background: linear-gradient(250.55deg, #D8B40C 25.24%, rgba(216, 180, 12, 0) 52.89%, #D8B40C 90.19%);
    border: 2px solid transparent;
    .badge {
      color: #D8B40C;
    }
  }

  &.inactive {
    border: 2px solid #4e4e4e;
    .wrap-img {
      filter: grayscale(100%);
      position: absolute;
      width: 42px;
      height: 42px;
    }
  }
}

img {
  width: 100%;
  height: auto;
}

.empty {
  display: block;
  margin: 5px;
  width: 40px;
  height: 40px;
}

.head-block {
  background: #000000;
  padding: 40px;
  z-index: 2;
  position: relative;
}

// Variable
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.direction-column {
  flex-direction: column;
}
.direction-row {
  flex-direction: row;
}
