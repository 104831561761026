.banner {
  position: relative;
  padding: 20px;
  border: 1px solid #4E4E4E;
  overflow: hidden;
  border-radius: 12px;
  margin-bottom: 35px;
  background: #27272F;
  z-index: 0;

  display: none;
  @media screen and (min-width: 1024px) {
    padding: 40px 50px;
  }
}

.holder {
  background: #c4c4c4;
  position: absolute;
  background: #007ad0;
  mix-blend-mode: hard-light;
  mix-blend-mode: color-dodge;
  opacity: 0.6;
  filter: blur(140px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.banner-group {
  flex-direction: column;
  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
}

.title-banner {
  font-size: 50px;
  font-weight: 700;
  line-height: 54px;
  color: #f6f6f6;
  z-index: 1;
}

.btn {
  cursor: pointer;
  font-weight: 900;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  color: #f6f6f6;
  padding: 17px 35px;
  background: #007bd0;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.44);
  border-radius: 8px;
  border: 0;
  z-index: 2;
  @media screen and (min-width: 768px) {
    margin-left: 40px;
  }
  &:hover {
    box-shadow: 0px 4px 37px rgb(0 140 241 / 63%);
  }
}

.image-wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 415px;
  height: 159px;
  img {
    width: 415px;
    height: 159px;
  }
}
