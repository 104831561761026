.tree {
  width: 100%;
  justify-content: center;
  @media screen and (min-width: 768px) {
    justify-content: space-between;
  }
}

.spec-wrapper {
  border: 1px solid #4e4e4e;
  border-radius: 12px;
  margin-bottom: 20px;
  width: 282px;
  overflow: hidden;
  margin-right: 0;
  // @media screen and (min-width: 425px) {
  //   margin-right: 15px;
  // }
  // @media screen and (min-width: 768px) {
  //   margin-right: 0;
  // }
  @media screen and (min-width: 1025px) {
    margin-right: 15px;
  }
}

.spec-header {
  padding: 12px;
  background: #27272f;
  height: 68px;
}

.spec-icon {
  border: 2px solid #4e4e4e;
  border-radius: 8px;
  margin-right: 8px;
  padding: 1px;
  width: 32px;
  height: 32px;

  img {
    border-radius: 6px;
  }
}

.spec-name {
  color: #f6f6f6;
}

.spec-reset {
  display: block;
  background: #b2b2c0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-image: url(../../public/images/close.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  margin-left: 8px;
}

.wrap-point,
.spec-points {
  color: rgba(82, 209, 72, 1);
}

.badge {
  position: absolute;
  width: 25px;
  height: 14px;
  border-radius: 2px;
  right: -8px;
  bottom: -7px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  color: #f6f6f6;
  background: #16161c;
  padding: 4px;

  &:hover {
    border: none;
    background: #16161c;
  }
}

// Glyphs
.glyphs-wrap {
  width: 282px;
  @media screen and (min-width: 768px) {
    height: 882px;
  }
}

.content-glyphs {
  background: #1b1b22;
  border: 1px solid #4e4e4e;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 24px;
}

.glyphs {
  padding: 24px;
  margin-bottom: 20px;
}

.btn-group {
  text-align: center;
  text-transform: uppercase;
}

.btn-clear,
.btn-copy {
  color: #f6f6f6;
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
  border-radius: 8px;
  padding: 22px;
  width: 100%;
  max-width: 100%;
  text-transform: uppercase;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 282px;
  .icon {
    width: 26px;
    height: 23px;
    margin-right: 8px;
  }
  &:hover {
    box-shadow: 0px 4px 37px rgb(0 140 241 / 63%);
  }
}

.btn-clear {
  background: rgba(20, 20, 24, 0.5);
  border: 2px solid #4e4e4e;
  border-radius: 8px;
  margin-bottom: 16px;
}

.btn-copy {
  border: 0;
  background: #007bd0;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.44);
}

.glyph-section {
  background: #141418;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 8px;
  height: 62px;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #5c5c68;
  cursor: pointer;
  .img-wrap {
    width: 42px;
    height: 42px;
    border: 2px solid #4e4e4e;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 10px;
  }
}

.glyphs-big {
  margin-bottom: 24px;
}

.type-g {
  display: inline-block;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #f7f7f7;
  margin-bottom: 16px;
}

// Popover
.popover {
  background: #1b1b22;
  border: 1px solid #4e4e4e;
  border-radius: 12px;
  padding: 20px;
  width: 300px;
  height: auto;
  @media screen and (min-width: 768px) {
    width: 500px;
  }
}

.popover-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #ffffff;
}

.popover-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}

.popover-btn {
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase;
  background: transparent;
  border: none;
  color: #53d148;
}

.rank {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  opacity: 0.6;
}
