.footer-group {
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

.logo {
  margin-bottom: 30px;
  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}

.footer-logo-wrap {
  display: block;
}

.footer-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: #f6f6f6;
  opacity: 0.6;
  margin-bottom: 30px;
  padding: 15px;
  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}

.social {
  padding: 0;
  margin: 0;
  list-style-type: none;
  flex-wrap: wrap;
  justify-content: center;

  display: none;
  @media screen and (min-width: 768px) {
    flex-wrap: nowrap;
  }
  li {
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      background: rgba(#f6f6f6, 0.1);
      box-shadow: 0px 4px 35px #141226;
      border-radius: 3000px;
      transform: rotate(-180deg);
      margin-left: 7px;
      margin-right: 7px;
      @media screen and (min-width: 768px) {
        margin-left: 0;
        margin-right: 15px;
      }
      &:hover {
        opacity: 0.7;
        box-shadow: 0px 4px 37px rgb(0 140 241 / 63%);
      }
    }
  }
}

.footer-logo {
  width: 260px;
}

.social-icon {
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
}

.footer-link {
  text-decoration: none;
  color: #f6f6f6;
}
