.seo-content {
    margin: 0 auto 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    @media screen and (min-width: 768px) {
        padding: 30px;
    }
    p {
        opacity: 0.6;
    }
}

.seo-title {
    font-weight: 700;
    font-size: 30px;
    line-height: 43px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-align: center;
    color: #F6F6F6;
    @media screen and (min-width: 768px) {
        text-align: left;
        font-size: 40px; 
    }
}

.wrap-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: 1024px) {
        flex-direction: row;
    }
    .wrapp-img {
        display: none;
        @media screen and (min-width: 1024px) {
            margin-right: 24px;
            min-width: 400px;
        }
        img {
            border-radius: 12px;
            overflow: hidden;
        }
    }
}